<template>
  <div class="pb-10">
    <GoBack @click="$emit('back')" text="Back" class="max-w-max mb-[45px]" />

    <p class="text-base font-normal text-[#494456] mb-[24px]">
      Which of our products are you interested in? (You can select more than one
      products)
    </p>

    <div class="grid grid-cols-1 gap-[10px]">
      <ProductCards
        v-for="app in ametaApps"
        :key="app.id"
        @click="manageProducts(app.id)"
        :img="app.logoUrl"
        :text="app.description"
        :selected="selectedProducts.includes(app.id)"
      />
      <!-- <ProductCards
        @click="manageProducts('AmetaCore')"
        :img="require('@/assets/images/logo1.svg')"
        text="Use our cloud native core banking solution powered by Apache fineract to build your own bank"
        :selected="selectedProducts.includes('AmetaCore')"
      />

      <ProductCards
        @click="manageProducts('AmetaId')"
        :img="require('@/assets/images/logo5.svg')"
        text="Verify your customer’s identity, gain deeper insights about your users and prevent fraud in real-time across Africa"
        :selected="selectedProducts.includes('AmetaId')"
      />

      <ProductCards
        @click="manageProducts('AmetaBanking')"
        :img="require('@/assets/images/logo4.svg')"
        text="AmetaBanking helps you embed powerful and customizable digital banking solutions into your products"
        :selected="selectedProducts.includes('AmetaBanking')"
      />
      <ProductCards
        @click="manageProducts('AmetaSavings')"
        :img="require('@/assets/images/logo3.svg')"
        text="Give your customers access to no loss lottery and Prize-linked savings account"
        :selected="selectedProducts.includes('AmetaSavings')"
      /> -->
    </div>

    <button
      @click="$emit('signupUser', selectedProducts)"
      class="h-52px text-white fs-16 fw-500 bluebtn br-8 flex items-center justify-center w-full mt-[19px]"
    >
      {{ $t("message.createaccount") }}

      <div v-if="props.signupLoading" class="h-4 w-4 ml-4 rounded-md block">
        <div class="roundLoader opacity-50 mx-auto"></div>
      </div>
    </button>
  </div>
</template>

<script setup>
import ProductCards from "@/views/signup/ProductCards.vue";
import GoBack from "@/components/reusable/BackToWallet.vue";
import { ref, defineProps, onMounted } from "vue";
const props = defineProps(["signupLoading", "ametaApps"]);

onMounted(() => {
  // getAppList();
});
// const loading = ref(false);
const selectedProducts = ref([]);

const manageProducts = (appId) => {
  if (selectedProducts.value.includes(appId)) {
    selectedProducts.value = selectedProducts.value.filter((e) => e !== appId);
  } else {
    selectedProducts.value.push(appId);
  }
  console.log(selectedProducts.value);
};
</script>
