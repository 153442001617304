import { Web, Constants } from "@/components/util";
import store from "@/store/index.js";
import { Log } from "@/components/util";
import { decryptData } from "@/components/util/Crypto";

export default class LoginService {
  static loginUser(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/signin",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static resetPassword(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/reset-password",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static confirmEmail(userId, code, successHandler, errorHandler) {
    Web.get(
      // Onboarding/confirm-email?userId=www&code=wwwww
      Constants.API_BASE +
        "/Onboarding/confirm-email?userId=" +
        userId +
        "&code=" +
        code,
      successHandler,
      errorHandler
    );
  }

  static getRefreshToken(refreshToken, successHandler, errorHandler) {
    //
    Web.postRefreshToken(
      Constants.API_BASE + "/Onboarding/refresh-token",
      "",
      refreshToken,

      successHandler,
      errorHandler
    );
  }

  // static replaceToken(refreshToken) {
  // 	LoginService.getRefreshToken(
  // 		refreshToken,

  // 		(response) => {
  // 			const data = response.data.data;
  // 			store.commit("authToken/apiToken", data.jwToken);
  // 			store.commit("authToken/refreshToken", data.refreshToken)
  // 			Log.info("tokenData:" + JSON.stringify(data))
  // 		},
  // 		(error) => {
  // 			Log.info("Tokenerror: " + error)
  // 		}
  // 	)
  // }
  static refreshToken = () => {
    LoginService.getRefreshToken(
      {
        token: store.getters["authToken/refreshToken"],
      },
      (response) => {
        const data = response.data.data;
        store.commit("authToken/apiToken", data.jwToken);
        store.commit("authToken/refreshToken", data.refreshToken);

        Log.info("refreshAuth: " + JSON.stringify(response));
      },
      (error) => {
        Log.info("Tokenerror: " + error);
      }
    );
  };

  static handleSuccessfulLogin(response) {
    const data = response.data.data;

    store.commit("authToken/apiToken", data.jwToken ? data.jwToken : null);
    store.commit("authToken/isVerified", data.isVerified);
    store.commit("authToken/userId", data.id);
    store.commit("authToken/tenantId", data.tenantId);
    store.commit("authToken/email", decryptData(data.email));
    store.commit("authToken/roles", data.roles);
    store.commit("authToken/firstName", data.firstName);
    store.commit("authToken/lastName", data.lastName);
    store.commit("authToken/hasPin", data.hasPin);
    store.commit("authToken/dob", data.dob ? data.dob : new Date("01/01/1000"));
    store.commit(
      "authToken/phoneNumber",
      data.phoneNumber ? data.phoneNumber : ""
    );
    store.commit(
      "authToken/companyName",
      data.companyName ? data.companyName : "Unverified"
    );
    store.commit(
      "authToken/countryName",
      data.countryName ? data.countryName : ""
    );
    // store.commit("authToken/companyName", null);
    store.commit("authToken/isProfileUpdated", data.isProfileUpdated);
    store.commit("authToken/isPhoneNumberVerified", data.isPhoneNumberVerified);
    store.commit("authToken/isKycDone", data.isKycDone);
    store.commit("authToken/currency", data.currency || "NGN");
    store.commit("SET_ENVIRONMENT", data.environment.toLowerCase());

    //commit refreshToken
    store.commit(
      "authToken/refreshToken",
      data.refreshToken ? data.refreshToken : null
    );
  }

  static handleLogout(reason = null) {
    localStorage.clear();
    if (reason === "auto") {
      Web.navigate(`/login`);
    } else {
      Web.navigate("/login");
    }
  }
}
