<template>
  <div>
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_541_18322)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 15.5C0 7.229 6.729 0.5 15 0.5C23.271 0.5 30 7.229 30 15.5C30 23.771 23.271 30.5 15 30.5C6.729 30.5 0 23.771 0 15.5ZM11.5607 9.93934C10.9749 9.35355 10.0251 9.35355 9.43934 9.93934C8.85355 10.5251 8.85355 11.4749 9.43934 12.0607L12.8787 15.5L9.43934 18.9393C8.85355 19.5251 8.85355 20.4749 9.43934 21.0607C10.0251 21.6464 10.9749 21.6464 11.5607 21.0607L15 17.6213L18.4393 21.0607C19.0251 21.6464 19.9749 21.6464 20.5607 21.0607C21.1464 20.4749 21.1464 19.5251 20.5607 18.9393L17.1213 15.5L20.5607 12.0607C21.1464 11.4749 21.1464 10.5251 20.5607 9.93934C19.9749 9.35355 19.0251 9.35355 18.4393 9.93934L15 13.3787L11.5607 9.93934Z"
          fill="#D24A43"
        />
      </g>
      <defs>
        <clipPath id="clip0_541_18322">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CancelRed",
  setup() {
    return {};
  },
};
</script>
