import axios from "axios";
import store from "@/store";
import { computed } from "vue";
import Log from "./Log.js";
import Constants from "./Constants.js";

const environment = computed(() => store.getters.environment);
const baseUrl = computed(() => {
  if (environment.value === "live") {
    return process.env.VUE_APP_LIVE_BASE_API_URL;
  } else {
    return process.env.VUE_APP_LIVE_BASE_API_URL;
  }
});

const bankingUrl = computed(() => {
  if (environment.value === "live") {
    return process.env.VUE_APP_LIVE_AMETA_BANKING_URL;
  } else {
    return process.env.VUE_APP_TEST_AMETA_BANKING_URL;
  }
});

axios.interceptors.request.use(
  (req) => {
    const matchingExcludePaths = Constants.authExcludeApiPaths.filter(
      (value) => {
        req.url = req.url || "";
        let excludePath = req.url.indexOf(value) > -1;
        return excludePath;
      }
    );

    const setHeaders = () => {
      console.log("Url Banking: " + bankingUrl.value);

      if (baseUrl.value && req.url?.startsWith(baseUrl.value)) {
        req.headers.Authorization =
          "Bearer " + store.getters["authToken/apiToken"];
      }

      if (bankingUrl.value && req.url?.startsWith(bankingUrl.value)) {
        req.headers.Authorization =
          "Bearer " + store.getters["authToken/apiToken"];
        console.log("Url Banking: " + bankingUrl.value);

        // req.headers.clientKey = store.getters["authToken/clientLiveKey"];
      }

      if (
        process.env.VUE_APP_CUSTOMER_SERVICE &&
        req.url?.startsWith(process.env.VUE_APP_CUSTOMER_SERVICE)
      ) {
        req.headers.Authorization =
          "Bearer " + store.getters["authToken/apiToken"];
        req.headers.clientKey = store.getters["authToken/clientLiveKey"];
      }

      if (
        process.env.VUE_APP_BO_BASE_URL &&
        req.url?.startsWith(process.env.VUE_APP_BO_BASE_URL)
      ) {
        req.headers.Authorization =
          "Bearer " + store.getters["authToken/apiToken"];
      }

      // Log.info("reqH: " + JSON.stringify(req))
    };

    //check if url should not exclude authentication
    if (matchingExcludePaths.length === 0) {
      setHeaders();
    }

    return req;
  },
  function (error) {
    Log.info("axios-error" + error);
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!store.getters["authToken/apiToken"]) {
      // localStorage.clear();
      // window.location.href = "/";
    }
    const originalRequest = error?.config;

    if (
      error?.response?.status == 401 &&
      !originalRequest._retry &&
      error.response.data.Message.toLowerCase() === "you are not authorized"
    ) {
      originalRequest._retry = true;
      const token = await refreshToken();

      if (token) {
        originalRequest.headers["Authorization"] = "Bearer " + token.jwToken;
      }
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);
function refreshToken() {
  // return new Promise((resolve) => {
  return Web.post(
    Constants.API_BASE + "/Onboarding/refresh-token",
    {
      token: store.getters["authToken/refreshToken"],
    },

    (response) => {
      const data = response.data.data;
      store.commit("authToken/apiToken", data.jwToken);
      store.commit("authToken/refreshToken", data.refreshToken);

      const fakeData = {
        jwToken: "JwToken",
        refresh: "RefreshToken",
      };

      // return data;
      return fakeData;
    },
    () => {
      localStorage.clear();
      window.location.href = "/";
    }
  );
  // });
}
export default class Web {
  static BASE_URL = baseUrl.value;
  static BO_BASE_URL = process.env.VUE_APP_BO_BASE_URL;
  static CUSTOMER_SERVICE = process.env.VUE_APP_CUSTOMER_SERVICE;
  static BANKING_SERVICE = bankingUrl.value;

  static get(url, successCallback, errorCallback) {
    Web.getAbsolute(baseUrl.value + url, successCallback, errorCallback);
  }

  static getBackOffice(url, successCallback, errorCallback) {
    Web.getAbsolute(Web.BO_BASE_URL + url, successCallback, errorCallback);
  }

  static getBanking(url, successCallback, errorCallback) {
    Web.getAbsolute(
      // Web.BANKING_SERVICE
      bankingUrl.value + url,
      successCallback,
      errorCallback
    );
  }

  static getCustomer(url, successCallback, errorCallback) {
    Web.getAbsolute(Web.CUSTOMER_SERVICE + url, successCallback, errorCallback);
  }

  static getAbsolute(url, successCallback, errorCallback) {
    axios.get(url).then(successCallback).catch(errorCallback);
  }

  static navigate(url) {
    window.location.href = url;
  }

  static post(url, data, successCallback, errorCallback) {
    Web.postAbsolute(baseUrl.value + url, data, successCallback, errorCallback);
  }

  static postGateway(url, data, successCallback, errorCallback) {
    // console.log("Banking Service api env : " + Web.BANKING_SERVICE);
    // console.log("BDApi :" + bankingUrl.value);

    Web.postAbsolute(
      // Web.BANKING_SERVICE + url,
      bankingUrl.value + url,
      data,
      successCallback,
      errorCallback
    );
  }

  static postBackOffice(url, data, successCallback, errorCallback) {
    Web.postAbsolute(
      Web.BO_BASE_URL + url,
      data,
      successCallback,
      errorCallback
    );
  }

  static postAbsolute(url, data, successCallback, errorCallback) {
    axios.post(url, data).then(successCallback).catch(errorCallback);
  }

  static postCustomer(url, data, successCallback, errorCallback) {
    Web.postAbsolute(
      Web.CUSTOMER_SERVICE + url,
      data,
      successCallback,
      errorCallback
    );
  }

  static postRefreshToken(
    url,
    data,
    refreshToken,
    successCallback,
    errorCallback
  ) {
    axios
      .post(baseUrl.value + url, data, {
        headers: {
          refreshToken: refreshToken,
          // 'Authorization': "Bearer" + " " + jwToken
        },
      })
      .then(successCallback)
      .catch(errorCallback);
  }

  static put(url, data, successCallback, errorCallback) {
    Web.putAbsolute(baseUrl.value + url, data, successCallback, errorCallback);
  }

  static putAbsolute(url, data, successCallback, errorCallback) {
    axios.put(url, data).then(successCallback).catch(errorCallback);
  }

  static delete(url, successCallback, errorCallback) {
    Web.deleteAbsolute(baseUrl.value + url, successCallback, errorCallback);
  }

  static deleteBackOffice(url, successCallback, errorCallback) {
    Web.deleteAbsolute(Web.BO_BASE_URL + url, successCallback, errorCallback);
  }

  static deleteAbsolute(url, successCallback, errorCallback) {
    axios.delete(url).then(successCallback).catch(errorCallback);
  }

  static patch(url, successCallback, errorCallback) {
    Web.patchAbsolute(baseUrl.value + url, successCallback, errorCallback);
  }

  static patchBackOffice(url, successCallback, errorCallback) {
    Web.patchAbsolute(Web.BO_BASE_URL + url, successCallback, errorCallback);
  }

  static patchAbsolute(url, successCallback, errorCallback) {
    axios.patch(url).then(successCallback).catch(errorCallback);
  }
}
