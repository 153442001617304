import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./tailwind.css";
import MittEmitter from "mitt-emitter";
import "./assets/css/styles.scss";
import "./assets/css/base.css";
import VueChartkick from "vue-chartkick";
import formatCurrency from "@/components/util/formatCurrency";
import VueNumerals from "vue-numerals";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { createI18n } from "vue-i18n";
import "chartkick/chart.js";
import { messages as frMessage } from "@/plugins/locales/locale-fr";
import { messages as enMessage } from "./plugins/locales/locale-en";
import VueCryptojs from "vue-cryptojs";

const emitter = new MittEmitter();

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).forEach(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
  window.scrollTo(0, 0);
});

const messages = {
  english: {
    message: enMessage,
  },
  french: {
    message: frMessage,
  },
};

const i18n = createI18n({
  legacy: false,
  // something vue-i18n options here ...
  locale: "english", // set locale
  fallbackLocale: "english", // set fallback locale
  messages,
});

createApp(App)
  .use(store)
  .use(VueChartkick)
  .use(VueNumerals)

  .use(VCalendar, {})
  .provide("formatCurrency", formatCurrency)
  .use(VueCryptojs)
  .use(i18n)
  .use(router)
  .provide("emitter", emitter)
  .mount("#app");
