// import { Log } from "@/components/util";
const state = {
  editDirectorDetails: {},
};

const getters = {
  editDirectorDetails(state) {
    return state.editDirectorDetails;
  },
};

const mutations = {
  editDirectorDetails(state, editDirectorDetails) {
    state.editDirectorDetails = editDirectorDetails;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
