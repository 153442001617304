<template>
  <div
    :class="selected ? 'activeCardStyle' : 'cardStyle'"
    class="py-[25px] px-[24px] flex flex-col cursor-pointer"
  >
    <div class="flex items-center justify-between">
      <img
        :src="img"
        alt="logo"
        width="100"
        height="25"
        class="w-[100px] h-[25px]"
      />

      <div v-if="selected">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 6L9 17L4 12"
            stroke="#3f91bc"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <p class="mt-2 text-sm font-normal text-[#2C2934]">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    text: String,
    selected: Boolean,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.cardStyle {
  background: #ffffff;
  /* Form / Border */

  border: 1px solid #e4e6eb;
  /* xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
}

.activeCardStyle {
  background: rgba(90, 140, 222, 0.09);
  /* Form / Border */

  border: 1px solid #e4e6eb;
  /* xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
}
</style>
