import UserActions from "@/services/userActions/userActions.js";
import store from "..";
import { Log } from "@/components/util";

const state = {
  banks: [],
  pin: "",
  transType: 0,
  amount: 0,
  rateId: "",
  beneficiaryName: "",
  beneficiaryAccountNumber: "",
  bankName: "",
  abaRoutingNumber: "",
  bankAddress: "",
  balance: "",
  amountToReceive: 0,
  withdrawalFee: 0,
  depositFee: 0,
  walletId: 0,
  customerWalletType: 0,
  naijaBankDetails: {},
  storedUploadData: {
    type: null,
    value: null,
  },
};

const getters = {
  // details(state) {
  // 	return state.details
  // }
  banks(state) {
    return state.banks;
  },
  customerWalletType(state) {
    return state.customerWalletType;
  },
  transType(state) {
    return state.transType;
  },
  naijaBankDetails(state) {
    return state.naijaBankDetails;
  },
  walletId(state) {
    return state.walletId;
  },
  depositFee(state) {
    return state.depositFee;
  },

  withdrawalFee(state) {
    return state.withdrawalFee;
  },

  pin(state) {
    return state.pin;
  },
  amountToReceive(state) {
    return state.amountToReceive;
  },
  balance(state) {
    return state.balance;
  },
  amount(state) {
    return state.amount;
  },
  rateId(state) {
    return state.rateId;
  },
  beneficiaryName(state) {
    return state.beneficiaryName;
  },
  beneficiaryAccountNumber(state) {
    return state.beneficiaryAccountNumber;
  },

  bankName(state) {
    return state.bankName;
  },
  bankAddress(state) {
    return state.bankAddress;
  },
  abaRoutingNumber(state) {
    return state.abaRoutingNumber;
  },
  storedUploadData(state) {
    return state.storedUploadData;
  },
};

const mutations = {
  banks(state, banks) {
    state.banks = banks;
  },
  customerWalletType(state, customerWalletType) {
    state.customerWalletType = customerWalletType;
  },
  transType(state, transType) {
    state.transType = transType;
  },

  naijaBankDetails(state, naijaBankDetails) {
    state.naijaBankDetails = naijaBankDetails;
  },
  walletId(state, walletId) {
    state.walletId = walletId;
  },
  depositFee(state, depositFee) {
    state.depositFee = depositFee;
  },
  withdrawalFee(state, withdrawalFee) {
    state.withdrawalFee = withdrawalFee;
  },
  pin(state, pin) {
    state.pin = pin;
  },
  amountToReceive(state, amountToReceive) {
    state.amountToReceive = amountToReceive;
  },
  balance(state, balance) {
    state.balance = balance;
  },
  amount(state, amount) {
    state.amount = amount;
  },
  rateId(state, rateId) {
    state.rateId = rateId;
  },
  beneficiaryName(state, beneficiaryName) {
    state.beneficiaryName = beneficiaryName;
  },

  beneficiaryAccountNumber(state, beneficiaryAccountNumber) {
    state.beneficiaryAccountNumber = beneficiaryAccountNumber;
  },
  bankName(state, bankName) {
    state.bankName = bankName;
  },
  bankAddress(state, bankAddress) {
    state.bankAddress = bankAddress;
  },
  abaRoutingNumber(state, abaRoutingNumber) {
    state.abaRoutingNumber = abaRoutingNumber;
  },
  storedUploadData(state, storedUploadData) {
    state.storedUploadData = storedUploadData;
  },
};

const actions = {
  getBanks() {
    UserActions.getBankList(
      (res) => {
        store.commit("bankDetails/banks", res.data.data);
      },
      (err) => {
        Log.error(err);
        this.getBanks();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
