import Web from "../../components/util/Web";
import Constants from "@/components/util/Constants.js";

export default class SignupService {
  static signupUser(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/sign-up",
      userDetails,
      successHandler,
      errorHandler
    );
  }
  static getAppsOnSignup(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + "/Onboarding/apps",
      successHandler,
      errorHandler
    );
  }

  static confirmEmail(data, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/confirm-email",
      data,
      successHandler,
      errorHandler
    );
  }

  static resendVerificationEmail(data, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/resend-email-confirmation-otp",
      data,
      successHandler,
      errorHandler
    );
  }

  static getApps(successHandler, errorHandler) {
    Web.get(
      // Account/confirm-email?userId=www&code=wwwww
      Constants.API_BASE + "/Onboarding/apps",
      successHandler,
      errorHandler
    );
  }
}

// {
//   ametapay:  "Collect money and make payments in more than 17 African countries",
//   ametacore: "Use our cloud native core banking solution powered by Apache fineract to build your own bank",
//   ametaId: "Verify your customer’s identity, gain deeper insights about your users and prevent fraud in real-time across Africa",
//   ametaBanking: "AmetaBanking helps you embed powerful and customizable digital banking solutions into your products",
//   ametaSavings: "Give your customers access to no loss lottery and Prize-linked savings account"

// }
