<template>
  <div class="logoAndAvatar">
    <div class="h-full w-full">
      <img src="/logo-black.png" class="w-auto h-6 md:h-9" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SuprBizLogo",
};
</script>
