/* eslint-disable no-unused-vars */
import { Web, Constants } from "@/components/util";

export default class UserActions {
  //counterparties
  static addCounterparty(credentials, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Counterparties/save",
      credentials,
      successHandler,
      errorHandler
    );
  }
  static userHasPin(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Account/has-pin`,
      successHandler,
      errorHandler
    );
  }

  //   static getCorporateAccounts( {
  //   PageNumber,
  //   PageSize,

  //   MerchantId,

  // successHandler,
  // errorHandler
  // ) {
  // Web.get(
  //   Constants.API_BASE +
  //     `/InvoiceAccounts?PageSize=${PageSize}&PageNumber=${PageNumber}&MerchantId=${MerchantId}&InvoiceStatus=${InvoiceStatus}&Counterparty=${Counterparty}&AccountName=${AccountName}&RecuringInvoice=${RecuringInvoice}&GlobalSearch=${GlobalSearch}`,
  //   successHandler,
  //   errorHandler
  // );
  // }

  static getCounterpartyTransactions(
    merchantId,
    counterpartyId,
    PageNumber,
    PageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Counterparties/transactions?counterpartyId=${counterpartyId}&merchantId=${merchantId}&pageNumber=${PageNumber}&pageSize=${PageSize}`,
      successHandler,
      errorHandler
    );
  }

  static getCorporateAccounts(
    PageNumber,
    PageSize,
    MerchantId,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/InvoiceAccounts?merchantId=${MerchantId}&pageSize=${PageSize}&pageNumber=${PageNumber}`,
      successHandler,
      errorHandler
    );
  }

  static getAllCounterparties(
    merchantId,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Counterparties/get-by-merchant-id?MerchantId=${merchantId}&PageSize=${pageSize}&PageNumber=${pageNumber}`,
      successHandler,
      errorHandler
    );
  }

  static deleteCounterparty(counterpartyId, successHandler, errorHandler) {
    Web.delete(
      Constants.API_BASE + `/Counterparties/delete/${counterpartyId}`,
      successHandler,
      errorHandler
    );
  }

  static deleteCounterpartyAccount(accountId, successHandler, errorHandler) {
    Web.delete(
      Constants.API_BASE + `/Counterparties/accounts?id=${accountId}`,
      successHandler,
      errorHandler
    );
  }

  static addCounterpartyAccount(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Counterparties/accounts",
      details,
      successHandler,
      errorHandler
    );
  }

  static editCounterpartyAccount(credentials, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Counterparties/accounts",
      credentials,
      successHandler,
      errorHandler
    );
  }

  static editCounterparty(credentials, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Counterparties/update",
      credentials,
      successHandler,
      errorHandler
    );
  }

  static editCounterpartyGroup(credentials, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Counterparties/groups",
      credentials,
      successHandler,
      errorHandler
    );
  }

  static getCounterpartyInvoice(
    counterpartyId,
    merchantId,
    PageNumber,
    PageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Counterparties/invoices?counterpartyId=${counterpartyId}&merchantId=${merchantId}&pageNumber=${PageNumber}&pageSize=${PageSize}`,
      successHandler,
      errorHandler
    );
  }

  static getCounterpartyById(counterpartyId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Counterparties/get/${counterpartyId}`,
      successHandler,
      errorHandler
    );
  }

  static createCounterpartyGroup(credentials, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Counterparties/groups",
      credentials,
      successHandler,
      errorHandler
    );
  }

  static deleteCounterpartyGroup(counterpartyId, successHandler, errorHandler) {
    Web.delete(
      Constants.API_BASE + `/Counterparties/groups/${counterpartyId}`,
      successHandler,
      errorHandler
    );
  }

  static searchCounterparties(
    merchantId,
    searchText,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Counterparties/search?MerchantId=${merchantId}&SearchText=${searchText}&PageSize=${pageSize}&PageNumber=${pageNumber}`,
      successHandler,
      errorHandler
    );
  }

  static getCounterpartyGroup(
    merchantId,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Counterparties/groups?MerchantId=${merchantId}&PageSize=${pageSize}&PageNumber=${pageNumber}`,
      successHandler,
      errorHandler
    );
  }

  static getCounterpartyGroupById(
    counterpartyId,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE + `/Counterparties/groups/${counterpartyId}`,
      successHandler,
      errorHandler
    );
  }

  static getCounterpartyAccountInfoById(
    counterpartyId,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Counterparties/accounts?counterpartyId=${counterpartyId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      successHandler,
      errorHandler
    );
  }

  //products
  static createProduct(credentials, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Products",
      credentials,
      successHandler,
      errorHandler
    );
  }

  static getProducts(
    merchantId,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Products?merchantId=${merchantId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      successHandler,
      errorHandler
    );
  }
  static searchProducts(
    merchantId,
    searchText,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Products/search?merchantId=${merchantId}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`,
      successHandler,
      errorHandler
    );
  }

  static deleteProduct(productId, successHandler, errorHandler) {
    Web.delete(
      Constants.API_BASE + `/Products/${productId}`,
      successHandler,
      errorHandler
    );
  }

  static editProduct(credentials, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Products",
      credentials,
      successHandler,
      errorHandler
    );
  }

  //
  // /Report/invoice?PageSize=10&PageNumber=1&UserId=eeb4e424-b568-41dc-a03b-13b96a31c835&InvoiceStatus=1&startDate=2022-09-24&endDate=2022-09-25
  static getAccountStatement(
    pageNumber,
    pageSize,
    accountId,
    from,
    to,
    transactionType,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Report/${accountId}/transactions?pageNumber=${pageNumber}&pageSize=${pageSize}&startdate=${from}&endDate=${to}&transactionType=${transactionType}`,
      successHandler,
      errorHandler
    );
  }
  static getInvoiceReport(
    userid,
    status,
    pageNumber,
    pageSize,
    from,
    to,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Report/invoice?PageSize=${pageSize}&PageNumber=${pageNumber}&UserId=${userid}&InvoiceStatus=${status}&startDate=${from}&endDate=${to}`,
      successHandler,
      errorHandler
    );
  }

  // /Report/counterparty-payment?PageSize=10&PageNumber=1&CounterPartyId=eeb4e424-b568-41dc-a03b-13b96a31c835&AccountId=eeb4e424-b568-41dc-a03b-13b96a31c835&startDate=2021%2F10%2F1&endDate=2022%2F10%2F1&TransactionStatus=1

  static getCounterpartyStatement(
    counterpartyId,
    pageNumber,
    pageSize,
    from,
    to,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Report/counterparty-payment?PageSize=${pageSize}&PageNumber=${pageNumber}&CounterPartyId=${counterpartyId}&startDate=${from}&endDate=${to}`,
      successHandler,
      errorHandler
    );
  }

  static getPaymentReport(
    { pageNumber, pageSize, userId, accountId, from, to, status },
    successHandler,
    errorHandler
  ) {
    // http://localhost:708/api/v1/Invoice/PaymentReports?UserId=e28011ff-f29b-4710-a74c-c4572a5ff08d&AccountId=1d2aad52-6720-45fd-884c-02e1ed21a3f7&startDate=2022-09-30&endDate=2022-10-30&PageNumber=1&PageSize=10
    Web.get(
      Constants.API_BASE +
        `/Report/counterparty-payment?UserId=${userId}&AccountId=${accountId}&startDate=${from}&endDate=${to}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
      successHandler,
      errorHandler
    );
  }
  //

  static getEarnings(userId, period, type, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE +
        "/Wallets/get-earnings?userId=" +
        userId +
        "&Period=" +
        period +
        "&Type=" +
        type,
      successHandler,
      errorHandler
    );
  }

  static getDrawEndTime(customerId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE +
        `/Wallets/get-draw-end-time?CustomerId=${customerId}`,
      successHandler,
      errorHandler
    );
  }

  static getCharges(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + "/Transactions/get-charges",
      successHandler,
      errorHandler
    );
  }

  static confirmDeposit(credentials, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Transactions/confirm-deposit",
      credentials,
      successHandler,
      errorHandler
    );
  }

  static transactionDeposit(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Transactions/manual-deposit",
      details,
      successHandler,
      errorHandler
    );
  }

  static transactionWithdrawal(bankDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Transactions/withdraw",
      bankDetails,
      successHandler,
      errorHandler
    );
  }

  static getNaijaBeneficiary(bankDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Transactions/resolve-nuban",
      bankDetails,
      successHandler,
      errorHandler
    );
  }

  static getPrevBeneficiaries(
    userId,
    pageNumber,
    pageSize,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Transactions/${userId}/beneficiaries?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      successHandler,
      errorHandler
    );
  }

  static naijaWithdrawal(bankDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Transactions/withdraw-nigerian",
      bankDetails,
      successHandler,
      errorHandler
    );
  }

  static getNaijaBankAccountDetails(
    customerId,
    type,
    successHandler,
    errorHandler
  ) {
    Web.get(
      Constants.API_BASE +
        `/Transactions/${customerId}/bank-account-details?type=${type}`,
      successHandler,
      errorHandler
    );
  }

  static getBankDetails(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + "/Transactions/bornfree-bank-data",
      successHandler,
      errorHandler
    );
  }

  static getBankList(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + "/Transactions/banks",
      successHandler,
      errorHandler
    );
  }
  static getCustomerTransactions(merchantId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE +
        `/Transactions/merchant-customer-transactions/${merchantId}`,
      successHandler,
      errorHandler
    );
  }

  static getAllRates(successHandler, errorHandler) {
    Web.get(Constants.API_BASE + "/Rates/all", successHandler, errorHandler);
  }

  static forgotPasswordApi(userEmail, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/forgot-password",
      userEmail,
      successHandler,
      errorHandler
    );
  }

  static setBusinessProfile(businessDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Kyc/business-profiles",
      businessDetails,
      successHandler,
      errorHandler
    );
  }

  //Compliance BusinessDetails

  static saveBusinessDetails(businessDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/compliance/business/create",
      businessDetails,
      successHandler,
      errorHandler
    );
  }
  static getBusinessDetails(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Compliance/business/get`,
      successHandler,
      errorHandler
    );
  }

  static updateCompliance(businessDetails, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Compliance/business/update",
      businessDetails,
      successHandler,
      errorHandler
    );
  }

  // Compliance BankAccounts

  static getBankAccount(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Compliance/bank/get`,
      successHandler,
      errorHandler
    );
  }

  static saveBankAccount(bankDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + `/Compliance/bank/create`,
      bankDetails,
      successHandler,
      errorHandler
    );
  }

  static updateBankAccount(bankDetails, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Compliance/bank/update",
      bankDetails,
      successHandler,
      errorHandler
    );
  }

  //Compliance Directors

  static createDirector(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Compliance/directors/create",
      details,
      successHandler,
      errorHandler
    );
  }

  static deleteDirector(id, successHandler, errorHandler) {
    Web.delete(
      Constants.API_BASE + `/Compliance/directors/${id}/delete`,
      successHandler,
      errorHandler
    );
  }

  static editDirectorDetails(details, successHandler, errorHandler) {
    Web.put(
      Constants.API_BASE + "/Compliance/directors/update",
      details,
      successHandler,
      errorHandler
    );
  }

  static getDirectors(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Compliance/directors/get`,
      successHandler,
      errorHandler
    );
  }

  static compliancePersonalUpload(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Kyc/compliances/personal/upload",
      details,
      successHandler,
      errorHandler
    );
  }

  //
  static businessVerification(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Kyc/compliances/business/create",
      details,
      successHandler,
      errorHandler
    );
  }

  static getStates(stateId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + "/Kyc/countries/" + stateId + "/states",
      successHandler,
      errorHandler
    );
  }

  static getCountries(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + "/Onboarding/countries",
      successHandler,
      errorHandler
    );
  }

  static getCustomers(merchantId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Customers/${merchantId}/all-customers`,
      successHandler,
      errorHandler
    );
  }

  static resetPassword(userDetails, successHandler, errorhandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/reset-password",
      userDetails,
      successHandler,
      errorhandler
    );
  }

  static merchantUpdateProfile(userDetails, successHandler, errorhandler) {
    Web.post(
      Constants.API_BASE + "/Merchant/update",
      userDetails,
      successHandler,
      errorhandler
    );
  }

  static changePassword(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Onboarding/change-password",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static confirmPhoneNumber(userDetails, successHandler, errorhandler) {
    Web.post(
      Constants.API_BASE + "/Account/confirm-phone-number",
      userDetails,
      successHandler,
      errorhandler
    );
  }

  static updateProfile(userDetails, successHandler, errorhandler) {
    Web.post(
      Constants.API_BASE + "/Account/update-profile",
      userDetails,
      successHandler,
      errorhandler
    );
  }

  static createPIN(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Account/create-pin",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static changePIN(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Account/change-pin",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static forgotPin(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Account/forgot-pin`,
      successHandler,
      errorHandler
    );
  }

  static resetPin(pinDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Account/reset-pin",
      pinDetails,
      successHandler,
      errorHandler
    );
  }

  static getComplianceDetails(userId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Kyc/compliances/business/${userId}`,
      successHandler,
      errorHandler
    );
  }

  static getPersonalCompliance(userId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Kyc/compliances/personal/${userId}/details`,
      successHandler,
      errorHandler
    );
  }

  static getProfileDetails(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Onboarding/user-profile`,
      successHandler,
      errorHandler
    );
  }

  static postDeviceInfo(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Account/device-data",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static subscribeDevice(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Device/subscribe-device",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  //Notifications

  static getAllNotifications() {
    // userId,
    // pageSize,
    // pageNumber,
    // successHandler,
    // errorHandler
    // Web.get(
    //   Constants.API_BASE +
    //     `/Notifications/all?UserId=${userId}&PageSize=${pageSize}&PageNumber=${pageNumber}`,
    //   successHandler,
    //   errorHandler
    // );
  }

  static getUserNotification(userId, successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/Notifications/${userId}`,
      successHandler,
      errorHandler
    );
  }

  static getCurrencies(successHandler, errorHandler) {
    Web.get(
      Constants.API_BASE + `/currency/getall`,
      successHandler,
      errorHandler
    );
  }

  static deleteUserNotification(id, successHandler, errorHandler) {
    Web.delete(
      Constants.API_BASE + `/Notifications/${id}`,
      successHandler,
      errorHandler
    );
  }

  static markReadNotification(id, successHandler, errorHandler) {
    Web.patch(
      Constants.API_BASE + `/Notifications/${id}/read`,
      successHandler,
      errorHandler
    );
  }

  static muteNotifications(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Notifications/mute",
      details,
      successHandler,
      errorHandler
    );
  }

  static unmuteNotifications(details, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Notifications/unmute",
      details,
      successHandler,
      errorHandler
    );
  }

  static resendPhonenumberConfirmation(obj, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + `/Account/resend-phonenumber-confirmation-code`,
      obj,
      successHandler,
      errorHandler
    );
  }
}
