<template>
  <div class="h-screen max-h-screen bg-white">
    <div class="flex min-h-screen h-screen">
      <div class="w-full max-w-[50%] hidden md:flex justify-center">
        <OnboardingImageSection class="" />
      </div>
      <div class="w-full overflow-y-auto">
        <div class="w-full max-w-[460px] 2xl:max-w-[550px] mx-auto py-10 px-6">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingImageSection from "/src/components/reusable/OnboardingImageSection.vue";
export default {
  name: "OnboardingBaseLayout",
  components: {
    OnboardingImageSection,
    // HelloWorld,
  },
};
</script>
