<template>
  <div class="h-full">
    <SignupProduct
      v-if="nextPage"
      @back="toggleSignupPages"
      @signupUser="addProducts"
      :signupLoading="loading"
      :ametaApps="ametaApps"
    />
    <div v-else class="flex flex-col">
      <div class="mb-28"><SuprBizLogo /></div>
      <div class="flex flex-col pb-28">
        <h3 class="fs-36 grey-7 fw-600 lh-26">
          {{ $t("message.createanaccount") }}
        </h3>
        <p class="grey-5 fs-18 fw-400 mt-8 mb-10">
          {{ $t("message.letsgetstarted") }}
        </p>

        <Form
          @submit="continueSignup"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="flex flex-col"
        >
          <div class="grid grid-cols-2 gap-4">
            <div class="mb-6 col-span-1">
              <div class="relative">
                <Field
                  id="First Name"
                  name="firstName"
                  type="text"
                  autocomplete="off"
                  v-model="firstName"
                  required
                  class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                  :class="{ 'is-invalid inputError': errors.firstName }"
                />

                <span class="floating-label">{{
                  $t("message.firstname")
                }}</span>
              </div>
              <div class="invalid-feedback errorMsg">
                {{ errors.firstName }}
              </div>
            </div>

            <div class="mb-6 col-span-1">
              <div class="relative">
                <Field
                  id="Last Name"
                  name="lastName"
                  type="text"
                  autocomplete="off"
                  v-model="lastName"
                  required
                  class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                  :class="{ 'is-invalid inputError': errors.lastName }"
                />

                <span class="floating-label">{{ $t("message.lastname") }}</span>
              </div>
              <div class="invalid-feedback errorMsg">{{ errors.lastName }}</div>
            </div>
          </div>

          <div class="mb-6">
            <div class="relative">
              <Field
                id="Email"
                name="email"
                type="text"
                autocomplete="off"
                v-model="userEmail"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.email }"
              />

              <span class="floating-label">{{ $t("message.email") }}</span>
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.email }}</div>
          </div>

          <div class="mb-6">
            <div class="relative">
              <Field
                id="Password"
                name="password"
                type="password"
                autocomplete
                v-model="userPassword"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.password }"
              />

              <span class="floating-label">{{ $t("message.password") }}</span>
              <span class="showPasswordBtn cursor-pointer">
                <button
                  class="px-3"
                  v-if="!showPassword"
                  @click="show('Password')"
                >
                  {{ $t("message.show") }}
                </button>
                <button class="px-3" v-else @click="hide('Password')">
                  {{ $t("message.hide") }}
                </button>
              </span>
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.password }}</div>
          </div>

          <button
            class="h-52px text-white fs-16 fw-500 bluebtn br-8 flex items-center justify-center"
          >
            {{ $t("message.continue") }}

            <div v-if="loading" class="h-4 w-4 ml-4 rounded-md block">
              <div class="roundLoader opacity-50 mx-auto"></div>
            </div>
          </button>
        </Form>

        <div @click="goToLogin" class="cursor-pointer max-content mx-auto mt-8">
          {{ $t("message.alreadyhaveanaccount") }}
          <span class="blue-5">{{ $t("message.signin") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignupProduct from "@/views/signup/SignupProduct.vue";
// import NigerianFlag from "@/components/svg/NigerianFlag.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { reactive, toRefs, ref } from "vue";
import SignupService from "@/services/signup/SignupService.js";
import { Log, Util } from "@/components/util";
import SuprBizLogo from "@/components/svg/SuprBizLogo.vue";
import { encryptData } from "@/components/util/Crypto";
// import UserActions from "@/services/userActions/userActions.js";
// import { markRaw } from "vue";
// import {
//   Listbox,
//   ListboxButton,
//   // ListboxLabel,
//   ListboxOption,
//   ListboxOptions,
// } from "@headlessui/vue";

export default {
  name: "Signup",
  components: {
    SuprBizLogo,
    // NigerianFlag,
    SignupProduct,
    Form,
    Field,
    // Listbox,
    // ListboxButton,
    // // ListboxLabel,
    // ListboxOption,
    // ListboxOptions,
  },

  setup() {
    onMounted(() => {
      getAppList();
    });
    const store = useStore();
    const formDetails = ref({});
    const nextPage = ref(false);
    const router = useRouter();
    const showPassword = ref(false);
    const loading = ref(false);
    const ametaApps = ref([]);
    // const countries = ref([
    // 	{
    // 		currency: markRaw(NigerianFlag),
    // 		id: 1,
    // 		areaCode: "+234",
    // 	},
    // ]);
    const countries = ref([
      // {
      //   id: 1,
      //   currency: "NGN",
      //   phoneCode: 234,
      // },
    ]);

    const selected = ref({});

    const user = reactive({
      userEmail: "",
      userPassword: "",
      // fullName: "",
      firstName: "",
      lastName: "",
      // phoneNumber: "",
      emailError: "",
      passwordError: "",
      showEmailError: false,
      showPasswordError: false,

      // userType: "Corporate",
      // accountCreated: false,
    });

    const addProducts = (appIds) => {
      formDetails.value.applications = appIds;

      handleSignup();
    };

    const hide = (id) => {
      showPassword.value = false;
      document.getElementById(id).type = "password";
    };

    const show = (id) => {
      showPassword.value = true;
      document.getElementById(id).type = "text";
    };

    const schema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      // phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .test(
          "hasSpecialCharacter",
          "Password must contain special character",
          (val) => Util.hasSpecialCharacter(val)
        )
        .test("hasLowerCase", "Password must contain lower case", (val) =>
          Util.hasLowerCase(val)
        )
        .test("hasUpperCase", "Password must contain upper case", (val) =>
          Util.hasUpperCase(val)
        )
        .test("hasNumber", "Password must have number", (val) =>
          Util.hasNumber(val)
        )
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    });
    const goToLogin = () => {
      router.push("/login");
    };

    const toggleSignupPages = () => {
      nextPage.value = !nextPage.value;
      Log.info("nextpage:" + JSON.stringify(nextPage.value));
    };

    const continueSignup = (values) => {
      formDetails.value = {
        firstName: values.firstName,
        lastName: values.lastName,

        email: encryptData(values.email),
        password: encryptData(values.password),
        // countryId: selected.value.id,
      };

      if (ametaApps.value.length === 0) {
        Util.handleGlobalAlert(true, "failed", "An error occurred. Try again");

        getAppList();
        return;
      }

      toggleSignupPages();
    };

    const handleSignup = () => {
      Log.info("values:" + JSON.stringify(formDetails.value));
      // Log.info("signupUser:" + JSON.stringify(signupUser));

      loading.value = true;
      SignupService.signupUser(
        formDetails.value,
        (response) => {
          Log.info("response:" + JSON.stringify(response.response));
          store.commit("setSignupEmail", formDetails.value.email);
          loading.value = false;
          Util.handleGlobalAlert(true, "success", response.data.message);
          router.push(
            `/verification_code/${encodeURIComponent(formDetails.value.email)}`
          );
          // router.push("/account_created");
        },
        (error) => {
          loading.value = false;
          Log.error("error:" + JSON.stringify(error.response.data.message));

          Util.handleGlobalAlert(true, "failed", error.response.data.message);
        }
      );

      // Log.info(
      // 	"signupPayLoad:" +
      // 		JSON.stringify({
      // 			fullName: values.fullName,
      // 			phoneNumber: values.phoneNumber,
      // 			email: values.email,
      // 			password: values.password,
      // 			countryId: selected.value.id,
      // 		})
      // );
      // }
    };

    const getAppList = () => {
      SignupService.getApps(
        (res) => {
          // console.log(res);
          ametaApps.value = res.data.data.filter(
            (item) => item.name !== "AmetaSavings"
          );
        },
        (err) => {
          console.log(err);
          // getAppList();
        }
      );
    };

    return {
      ...toRefs(user),
      handleSignup,
      goToLogin,
      loading,
      schema,
      selected,
      countries,
      hide,
      show,
      showPassword,
      toggleSignupPages,
      nextPage,
      continueSignup,
      addProducts,
      ametaApps,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/inputs.scss";
</style>
