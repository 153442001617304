const CryptoJS = require("crypto-js");

export function encryptData(pvtData) {
  let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_KEY);
  let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_KEY);

  let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(pvtData), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  return encrypted;
}

export function decryptData(pvtData) {
  let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_KEY);
  let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_KEY);

  // decrypt the data and convert to string
  const decryptData = CryptoJS.AES.decrypt(pvtData, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
  return decryptData;
}
