<template>
  <div class="h-full">
    <div class="flex flex-col">
      <div class="mb-28"><SuprBizLogo /></div>
      <div class="flex flex-col pb-28">
        <!-- <div class="flex"> -->
        <WelcomeBack />

        <!-- </div> -->
        <p class="grey-5 fs-18 fw-400 mt-8 mb-10">
          {{ $t("message.loginyouraccount") }}
        </p>
        <FormErrorAlert
          @clicked="hideErrorAlert"
          v-if="showErrorAlert"
          :msg="errMsg"
        />
        <Form
          @submit="handleLogin"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="flex flex-col"
        >
          <div class="mb-4">
            <div class="relative">
              <Field
                id="Email"
                name="email"
                type="text"
                v-model="userEmail"
                autocomplete="off"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.email }"
              />

              <span class="floating-label z-20">
                {{ $t("message.emailaddress") }}</span
              >
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.email }}</div>
          </div>

          <div class="mb-6">
            <div class="relative group">
              <Field
                id="Password"
                name="password"
                type="password"
                autocomplete="off"
                v-model="userPassword"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm peer"
                :class="{ 'is-invalid inputError': errors.password }"
              />
              <span class="floating-label"> {{ $t("message.password") }}</span>
              <span class="showPasswordBtn cursor-pointer">
                <div
                  class="px-3 cursor-pointer"
                  v-if="!showPassword"
                  @click="show('Password')"
                >
                  {{ $t("message.show") }}
                </div>
                <div
                  class="px-3 cursor-pointer"
                  v-else
                  @click="hide('Password')"
                >
                  {{ $t("message.hide") }}
                </div>
              </span>
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.password }}</div>
          </div>

          <div class="mb-6">
            <span
              @click="goToPasswordReset"
              class="blue-7 fw-500 fs-14 cursor-pointer"
            >
              {{ $t("message.forgotpassword") }}
            </span>
          </div>

          <button
            :disabled="userEmail.length === 0 || userPassword.length === 0"
            :class="
              Object.keys(errors).length > 0 ||
              userEmail.length === 0 ||
              userPassword.length === 0
                ? 'opacity-25'
                : 'opacity-100'
            "
            class="h-52px text-white fs-16 fw-500 bluebtn br-8 flex items-center justify-center"
          >
            {{ $t("message.login") }}

            <div v-if="loading" class="h-4 w-4 ml-4 rounded-md block">
              <div class="roundLoader opacity-50 mx-auto"></div>
            </div>
          </button>
        </Form>

        <div
          @click="goToSignUp"
          class="cursor-pointer max-content mx-auto mt-8"
        >
          {{ $t("message.donthave") }}
          <span class="blue-5"> {{ $t("message.signup") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuprBizLogo from "@/components/svg/SuprBizLogo.vue";
import WelcomeBack from "@/components/svg/WelcomeBack.vue";
import FormErrorAlert from "@/components/FormErrorAlert.vue";

import { reactive, toRefs, ref } from "vue";
// import UserInfo from "@/services/userInfo/userInfo.js";
import SignupService from "@/services/signup/SignupService.js";
import { useRouter, useRoute } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import LoginService from "@/services/login/LoginService.js";
import { Log, Util } from "@/components/util";
// eslint-disable-next-line no-unused-vars
import { encryptData } from "@/components/util/Crypto";
// import { useStore } from "vuex";
// import { askForPermissioToReceiveNotifications }  wsfrom "@/push-notification";

export default {
  name: "Login",
  components: {
    SuprBizLogo,
    WelcomeBack,
    FormErrorAlert,
    Form,
    Field,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    // const store = useStore();
    const loading = ref(false);
    const errMsg = ref("");
    const showErrorAlert = ref(false);
    const showPassword = ref(false);

    const user = reactive({
      userEmail: "",
      userPassword: "",
    });

    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string().required("Password is required"),
    });

    const hide = (id) => {
      showPassword.value = false;
      document.getElementById(id).type = "password";
    };

    const show = (id) => {
      showPassword.value = true;
      document.getElementById(id).type = "text";
    };

    const hideErrorAlert = () => {
      showErrorAlert.value = false;
    };

    const handleLogin = (values) => {
      loading.value = true;
      LoginService.loginUser(
        {
          email: encryptData(values.email),
          password: encryptData(values.password),
        },
        (response) => {
          localStorage.setItem("userInfo", JSON.stringify(response.data.data));

          loading.value = false;
          LoginService.handleSuccessfulLogin(response);

          if (route.query.redirected_from) {
            router.push(route.query.redirected_from);
          } else {
            router.push("/dashboard");
          }

          Util.handleGlobalAlert(true, "success", "You are now logged in");
        },
        (error) => {
          Log.error("login error:" + JSON.stringify(error));

          // Log.info("it erred")
          loading.value = false;
          errMsg.value = error?.response?.data?.message?.replaceAll(".", " ");
          showErrorAlert.value = true;
          if (error?.response?.data?.message === "Account.Not.Confirmed") {
            resendCode(values.email);
          }
        }
      );
    };

    const goToSignUp = () => {
      router.push("/signup");
    };
    const goToPasswordReset = () => {
      router.push("/forgot-password");
    };
    const resendCode = (email) => {
      SignupService.resendVerificationEmail(
        { email: encryptData(email) },
        (res) => {
          if (res.status == 200) {
            Util.handleGlobalAlert(true, "success", "Email sent");
            router.push(
              `/verification_code/${encodeURIComponent(encryptData(email))}`
            );
          }
        },
        (error) => {
          loading.value = false;
          Util.handleGlobalAlert(true, "error", error.response.data.message);
        }
      );
    };
    return {
      ...toRefs(user),
      handleLogin,
      goToSignUp,
      schema,
      goToPasswordReset,
      loading,
      errMsg,
      showErrorAlert,
      hideErrorAlert,
      hide,
      show,
      showPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/inputs.scss";
</style>
