<template>
  <div class="my-2 flex items-center gap-3 cursor-pointer">
    <ArrowBack />
    <span v-if="text === 'Counterparties'">{{
      $t("message.counterparties")
    }}</span>
    <span v-else class="fw-400 fs-12 lh-18 text-[#575267]">{{ text }}</span>
  </div>
</template>

<script>
import ArrowBack from "@/components/svg/ArrowBack.vue";

export default {
  props: {
    text: String,
  },
  components: {
    ArrowBack,
  },
};
</script>
