export const messages = {
  dashboard: "Dashboard",
  searchbytype: "Search or type",
  accounts: "Accounts",
  account: "Account",
  wallets: "Wallets",
  mobilemoney: "Mobile Money",
  accountsettings: "Account Settings",
  counterparty: "Counterparty",
  virtualaccounts: "Virtual Accounts",
  newvirtualaccounts: "New Virtual Account",
  invoices: "Invoices",
  banktransfers: "Bank Transfers",
  products: "Products",
  reports: "Reports",
  compliance: "Compliance",
  manageaccount:
    "Manage your accounts, view transaction details and balances here",
  totalbalanceinallaccount: "Total Balance in all accounts",
  addaccount: "Add account",
  addcounterparties: "Add counterparties",
  lastactivity: "Last activity",
  priordaybalance: "Prior Day Balance",
  availablebalance: "Available Balance",
  filter: "Filter",
  search: "Search",
  previous: "Previous",
  next: "Next",
  selectcountry: "Select country",
  accountname: "Account name",
  selectbank: "Select bank",
  openanaccount: "Open an account",
  backtoaccounts: "Back to accounts",
  cancel: "Cancel",
  accept: "Accept",
  quantity: "Quantity",
  done: "Done",
  addtax: "Add tax",
  confirmyouraccountdetails: "Confirm your account details",
  back: "Back",
  confirm: "Confirm",
  bankname: "Bank name",
  newinvoice: "New invoice",
  transfertocounterparty: "Transfer to Counterparty",
  transfertorecent: "Transfer to beneficiary",
  currency: "Currency",
  selectcurrency: "Choose your preferred currency",
  inputtransactionpin: "Input Transaction PIN",
  success: "Success",
  accountcreatedsuccessfully: "Account created successfully",
  continue: "Continue",
  currentbalance: "Current balance",
  createatransfer: "Create a transfer",
  reference: "Reference",
  date: "Date",
  amount: "Amount",
  status: "Status",
  nothingtosee: "Nothing to see here at the moment",
  creategroup: "Create Group",
  addcounterparty: "Add Counterparty",
  allcounterparties: "All Counterparties",
  counterparties: "Counterparties",
  group: "Group",
  name: "Name",
  areyousureyouwanttodeletecounterparty:
    "Are you sure you want to delete this counterparty?",
  delete: "Delete",
  editcounterparty: "Edit counterparty",
  counterparrtname: "Counterparty name",
  emailaddress: "Email address",
  email: "Email",
  legalentityidentifier: "Legal entity identifier",
  isrequired: "is required",
  address: "Address",
  country: "Country",
  state: "State",
  city: "City",
  postalcode: "Postal code",
  createvirtual: "Create virtual account for this counterparty",
  selectaccount: "Select account",
  accounttype: "Account type",
  counterpartytype: "Counterparty type",

  accountnumber: "Account number",
  addbeneficiary: "Add Beneficiary",
  selectbeneficiary: "Select beneficiary",
  beneficiaryaccount: "Beneficiary account",
  additems: "Add items",

  counterpartyhasbeencreated: "Counterparty has been created",
  sendmoneytoyourcounterparties: "Send money to your counterparties",
  singletransfer: "Single transfer",
  bulktransfer: "Bulk transfer",
  singletransfers: "Single transfers",
  bulktransfers: "Bulk transfers",
  bulktransactions: "Bulk Transactions",
  transactions: "Transactions",
  descritpion: "Description",
  uploadfile: "Upload file",
  donwloadsamplefile: "Download sample file",
  transfers: "Transfers",
  addnewproduct: "Add new product",
  clicktoupload: "Click to upload or drag and drop files here",
  productname: "Product name",
  salesprice: "Sales price",
  productnumber: "Product number",
  description: "Description",
  choosefile: "Choose file",
  merchant: "Merchant",
  nonotifications: "No notifications",
  viewprofile: "View profile",
  logout: "Logout",
  youttransfer: "Your transfer has been processed",
  transfersuccess: "Transaction Success",
  addproduct: "Add product",
  producthasbeenadded: "Product has been added",
  selectareportyouwanttogenerate: "Select a report you want to generate",
  accountstatement: "Account statement",
  paymentreport: "Payment report",
  counterpartyreport: "Counterparty report",
  invoicereport: "Invoice report",
  selectcorporateaccount: "Select corporate account",
  fromdate: "From date",
  todate: "To date",
  type: "Type",
  selectcounterparty: "Select counterparty",
  invoicestatus: "Invoice status",
  businessdetails: "Business Details",
  bankaccount: "Bank Account",
  directors: "Directors",
  invoicelogo: "Invoice logo",
  uploadalogoruse: "Upload a logo or use in your invoice",
  paymentmethods: "Payment methods",
  chooseyourpreferredpayment: "Choose your preferred payment options",
  tax: "Tax",
  addnewtax: "Add new tax",
  taxname: "Tax Name",
  value: "Value",
  default: "Default",
  profile: "Profile",
  personalinformation: "Personal Information",
  fullname: "Full name",
  firstname: "First Name",
  lastname: "Last Name",
  dob: "Date of birth",
  savechanges: "Save changes",
  security: "Security",
  yourpasswordshould:
    "Your password needs to be a minimum of 8 characters and should include at least one uppercase letter, one lowercase letter, one number, and one special character",
  currentpassword: "Current password",
  newpassword: "New password",
  confirmpassword: "Confirm password",
  password: "Password",
  show: "Show",
  hide: "Hide",
  accesskeys: "Access keys",
  livekeys: "Live Secret key ",
  testkeys: "Test Secret key",
  encryptionkeys: "Encryption Key",
  createpasscode: "Create Pin",
  passcode: "Pin",
  input2fa: " Input your 2FA code to confirm this transaction",
  your6digit:
    "Your 6-digit PIN would be used to authenticate all your transactions",
  forgotyourpin: "Forgot your PIN? Reset it here",
  currentpasscode: "Current pin",
  newpasscode: "New pin",
  confirmpasscode: "Confirm pin",
  changepin: "Change PIN",
  manageyourvirtual: "Manage your virtual accounts here",
  totalamount: "Total Amount",
  totalpaid: "Total Paid",
  totaldue: "Total Due",
  total: "Total",
  authorizetransaction: "Authorize Transaction",
  authorizing: "Autorisation",
  payments: "Payments",
  recurringinvoice: "Recurring invoice",
  duedate: "Due date",
  receivingaccount: "Receiving account",
  product: "Product ",
  addanotheritem: "Add another item",
  subtotal: "Subtotal ",
  discount: "Discount ",
  addnotes: "Add notes (optional)",
  addtac: "Add tax",
  percentage: "Percentage",
  flat: "Flat",
  banktransfer: "Bank Transfer",
  paymentoptions: "Payment options",
  alsosendby: "Also send by",
  save: "Save",
  sendinvoice: "Send Invoice",
  invoicereminderoption: "Invoice reminder option",
  beforeduedate: "Before due date",
  onduedate: "On due date",
  afterduedate: "After due date",
  fourteendaysbefore: "14 days before",
  sevendaysbefore: "7 days before",
  threedaysbefore: "3 days before",
  threedaysafter: "3 days after",
  sevendaysafter: "7 days after",
  fourteendaysafter: "14 days after",
  recenttransfers: "Recent transfers",
  totalaccountbalance: "Total Account Balance",
  dueinvoices: "Due invoices",
  totalpayments: "Total payments",
  recentinvoice: "Recent invoice",
  recentpayment: "Recent payment",
  welcomeback: "Welcome",
  welcome: "Welcome",
  managekatsu:
    "Welcome to your Katsu Dashboard. View and manage all your Katsu products from here",
  pendingcompliance: "Your compliance documentation is pending",
  completecompliance:
    "You need to complete your compliance documentation to activate your account",
  proceedcomplaince: "Proceed to compliance",
  loginyouraccount: "Login your account",
  forgotpassword: "Forgot password",
  donthave: "Don't have an account?",
  signup: "Sign Up",
  login: "Login",
  createanaccount: "Create an account",
  letsgetstarted: "Let's get started",
  createaccount: "Create account",
  alreadyhaveanaccount: "Already have an account?",
  signin: "Sign In",
  backtomainmenu: "Back to main menu",
  selectcounterpartytype: "Select counterparty type",
  lifetime: "Lifetime",
  today: "Today",
  yesterday: "Yesterday",
  thisweek: "This week",
  thismonth: "This month",
  thisyear: "This year",
  paid: "Paid",
  issued: "Issued",
  all: "All",
  partiallypaid: "Partially paid",
  due: "Due",
  draft: "Draft",
  completed: "Completed",
  failed: "Failed",
  cancelled: "Cancelled",
  preview: "Preview",
  edit: "Edit",
  resendinvoice: "Resend Invoice",
  addpayment: "Add payment",
  stoprecurring: "Stop recurring",
  beneficiary: "Beneficiary",
  sendtoexisting: "Send money to new and exisiting beneficiaries",
  choosetype: "Choose recipient from most recent transfers",
  accountdetails: "Account Details",
  recipienttype: "Recipient Name",
  selectrecipeinttype: "Select a recipient type to start a new transfer",
  invoiceid: "Invoice ID",
  paymentid: "Payment ID",
  paidamount: "Paid Amount",
  paymentmode: "Payment mode",
  uploadname: "Upload name",
  uploaddate: " Upload date",
  totalrecords: " Total records",
  successcount: "Success count",
  failurecount: " Failure count",
  edittax: "Edit tax",
  updatetax: "Update tax",
  deletetax: "Are you sure you want to delete this setting?",
  editproduct: "Edit product",
  updateproduct: "Update product",
  counterpartyrequired: "Counterparty is required",
  receivingaccountrequired: "Receiving Account is required",
  duedaterequired: "Due date is required",
  credittransactions: "Credit Transactions",
  debittransactions: "Debit Transactions",
  fee: "Fee",
  settled: "Settled",
  source: "Source",
  client: "Client",
  virtualaccountname: "Virtual Account Name",
  comingsoon: "Service Coming Soon",
  viewdocumentation: "View Documentations",
  nothingtoseehereatthemoment: "Nothing to see here at the moment",
  notifications: "Notifications",
  katsuproducts: "Katsu Products",
  notransaction: "No transaction at the moment",
  recenttransactions: "Recent Transactions",
  pendingprofile: "Complete your profile",
  completeprofile:
    "You need to complete your profile to finish your registration",
  proceedprofile: "Proceed to profile",
  yes: "Yes",
  no: "No",
};
