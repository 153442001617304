export default class Constants {
  static API_BASE = "/api/v1";

  static authExcludeApiPaths = [
    // "/Onboarding",
    "sign-up",
    "signin",
    "forgot-password",
    "resend-email-confirmation-otp",
    "refresh-token",
    "confirm-email",
    "reset-password",
    // "change-password",
    "countries",
    // "verification_code",
    // "apps",
  ];

  static currencyFormat = "0,0.00";

  // static nairaSymbol = &#x20A6;

  static btcFormat = "0,0.00000000";
  static backOfficeAuth = ["BornfreeAdmin"];
  static merchantAuth = ["Merchant"];
  static dataTypes = [
    {
      id: 1,
      text1: "Create Business Profiles",
    },
    {
      id: 2,

      text1: "Create Business Accounts",
    },
    {
      id: 3,
      text1: "Create Loans",
    },

    {
      id: 4,
      text1: "Create Loan Repayments",
    },
  ];
}
